<template>
  <div>
    <v-container fluid>
      <page-title
        title="Equipment Groups View"
        subtitle="Browse and Manage your Equipment Groups!"
        :badge="this.total"
      />
      <v-row class="mt-0 mb-1" justify="space-between">
        <v-col md="auto" sm="12" cols="12">
          <div>
            <span style="font-weight: 500" class="mr-2">Group By</span>
            <v-sheet elevation="2" height="32" rounded="xl" class="d-inline-flex">
              <v-btn-toggle v-model="options.GroupBy" mandatory color="info" dense group rounded>
                <v-btn
                  :value="'Tag'"
                  color="white"
                  style="margin: 0 !important; padding: 0 0.65rem !important; text-transform: none"
                >
                  Tag
                </v-btn>
                <v-btn
                  :value="'Model'"
                  color="white"
                  style="margin: 0 !important; padding: 0 0.65rem !important; text-transform: none"
                >
                  Model
                </v-btn>
                <!-- <v-btn
                  :value="'Make'"
                  color="white"
                  style="margin: 0 !important; padding: 0 0.65rem !important; text-transform: none"
                >
                  Make
                </v-btn> -->
              </v-btn-toggle>
            </v-sheet>
            <span style="font-weight: 500" class="ml-4">Min items per group</span>
            <v-sheet
              elevation="2"
              height="28"
              rounded="xl"
              class="d-inline-flex align-center justify-center ml-2"
            >
              <counter v-model="options.MinCount" :min="1" :max="1000" />
            </v-sheet>
            <span style="font-weight: 500" class="ml-3 mr-2">Sort By</span>
            <v-sheet elevation="2" height="32" rounded="xl" class="d-inline-flex">
              <v-btn-toggle v-model="options.sortBy" mandatory color="info" dense group rounded>
                <v-btn
                  :value="'Key'"
                  color="white"
                  style="margin: 0 !important; text-transform: none"
                  >{{ options.GroupBy }}</v-btn
                >
                <v-btn
                  :value="'Count'"
                  color="white"
                  style="margin: 0 !important; text-transform: none"
                >
                  Count
                </v-btn>
              </v-btn-toggle>
            </v-sheet>
            <v-sheet elevation="2" height="32" rounded="xl" class="d-inline-flex ml-2">
              <v-btn-toggle v-model="options.sortDesc" mandatory color="info" dense group rounded>
                <v-tooltip top z-index="999" nudge-top="-4px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      :value="false"
                      color="white"
                      style="margin: 0 !important; text-transform: none"
                    >
                      <i v-if="options.sortBy == 'Key'" class="far fa-arrow-down-a-z"></i>
                      <i v-else class="far fa-arrow-down-1-9"></i>
                    </v-btn>
                  </template>
                  <span>Ascending</span>
                </v-tooltip>
                <v-tooltip top z-index="999" nudge-top="-4px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      :value="true"
                      color="white"
                      style="margin: 0 !important; text-transform: none"
                    >
                      <i v-if="options.sortBy == 'Key'" class="far fa-arrow-up-a-z"></i>
                      <i v-else class="far fa-arrow-up-1-9"></i>
                    </v-btn>
                  </template>
                  <span>Descending</span>
                </v-tooltip>
              </v-btn-toggle>
            </v-sheet>
          </div>
        </v-col>
        <v-col
          md="auto"
          sm="12"
          cols="12"
          class="d-flex flex-row align-center justify-end flex-wrap"
          style="gap: 0.5rem"
        >
          <filter-manager
            ref="filterManager"
            v-model="selectedFilters"
            :options.sync="options"
          ></filter-manager>
          <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
          <export-groups :options="options"></export-groups>
          <columns-visibility-control
            :defaults="defaultHeaders"
            :withColumnsOrder="true"
            v-model="headers"
            :storageKey="storageKey"
          />
          <!-- <v-text-field
            v-model="options.search"
            label="Search"
            ref="mainSearch"
            class="table-search-field"
            @focus="mainSearchInFocus = true"
            @blur="mainSearchInFocus = false"
            :style="{
              'max-width':
                mainSearchInFocus || (options.search != '' && options.search != null)
                  ? '200px'
                  : '110px',
            }"
            dense
            solo
            clearable
            hide-details
            prepend-inner-icon="far fa-search"
            :loading="loadingStates.table"
          >
          </v-text-field> -->
          <refresh :loading="loadingStates.table" @refresh="getDataDebounced()"></refresh>
        </v-col>
      </v-row>
      <v-data-iterator
        :items="entities"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page="options.itemsPerPage"
        :loading="loadingStates.table"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'far fa-arrow-to-left',
          lastIcon: 'far fa-arrow-to-right',
          prevIcon: 'far fa-angle-left',
          nextIcon: 'far fa-angle-right',
          itemsPerPageOptions: [15, 30, 50, 100],
        }"
        style="position: relative"
        class="equipment-group"
      >
        <template v-slot:default="props">
          <v-progress-linear
            color="info"
            indeterminate
            rounded
            absolute
            height="4"
            style="z-index: 99999"
            :active="loadingStates.table"
          ></v-progress-linear>
          <v-expansion-panels multiple>
            <v-expansion-panel v-for="(group, index) in props.items" :key="index">
              <v-expansion-panel-header class="px-4 py-0" :ripple="true">
                <v-row no-gutters>
                  <v-col cols="auto" md="3" class="d-flex align-center">
                    <span class="font-weight-bold">
                      {{ currentGroupBy }}:
                      <v-chip
                        style="
                          height: 22px;
                          min-width: 22px;
                          justify-content: center;
                          font-size: 12px;
                        "
                        color="secondary"
                        text-color="white"
                        class="ml-2"
                      >
                        {{ group.key }}
                      </v-chip>
                    </span>
                  </v-col>
                  <v-col cols="auto" class="d-flex align-center">
                    <span class="ml-2">
                      <v-chip
                        style="
                          height: 22px;
                          min-width: 22px;
                          justify-content: center;
                          font-size: 12px;
                        "
                        class="mr-1"
                        color="indigo"
                        text-color="white"
                      >
                        {{ group.count }}
                      </v-chip>
                      <span class="fs-12px indigo--text"> Equipment</span>
                    </span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card elevation="2">
                  <v-data-table
                    dense
                    :headers="tableHeader"
                    :items="group.items"
                    @click:row="rowClicked"
                    class="equipments-table"
                    :items-per-page="-1"
                    hide-default-footer
                  >
                    <template v-slot:[`item.name`]="{ item }">
                      <v-tooltip right z-index="999" nudge-right="-4px">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <div class="equipment-img">
                              <div
                                style="height: 100%; width: 100%"
                                v-viewer
                                @click.stop
                                v-if="item.imageUrl != null && item.imageUrl != ''"
                              >
                                <img
                                  :key="item.id + '_img'"
                                  :src="item.imageUrl"
                                  height="100%"
                                  width="100%"
                                />
                              </div>
                              <i
                                v-else-if="item.category != null"
                                :class="'fad ' + item.category.icon"
                              ></i>
                              <i v-else :class="'fad fa-plug'"></i>
                            </div>
                          </div>
                        </template>
                        <span>
                          <v-img
                            v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                            class="img"
                            :src="item.imageUrl"
                            width="250px"
                            height="250px"
                            contain
                          ></v-img>
                          <i
                            v-else-if="item.category != null"
                            :class="'fad ' + item.category.icon"
                            style="font-size: 32px; padding: 2rem"
                          ></i>
                          <i
                            v-else
                            :class="'fad fa-plug'"
                            style="font-size: 32px; padding: 2rem"
                          ></i>
                        </span>
                      </v-tooltip>
                    </template>

                    <template v-slot:[`item.category`]="{ item }">
                      <v-row no-gutters>
                        <v-col sm="auto" class="d-flex justify-content-start align-items-center">
                          <i
                            v-if="item.category != null"
                            :class="'equipment-icon fad ' + item.category.icon"
                          ></i>
                          <i v-else :class="'equipment-icon fad fa-plug'"></i>
                        </v-col>
                        <v-col class="d-flex align-center">
                          <span class="category-name" v-if="item.category">{{
                            item.category.name
                          }}</span>
                        </v-col>
                      </v-row>
                    </template>

                    <template v-slot:[`item.availability`]="{ item }">
                      <equipment-availability
                        :availability="item.availability"
                        mini
                      ></equipment-availability>
                    </template>

                    <template v-slot:[`item.tag`]="{ item }">
                      <div class="equipment-tag font-weight-bold">{{ item.tag }}</div>
                    </template>

                    <template v-slot:[`item.provider`]="{ item }">
                      <equipment-provider
                        v-if="item.provider"
                        :provider-name="item.provider.name"
                      ></equipment-provider>
                      <i v-else :class="'far fa-times pl-2 text--disabled'"></i>
                    </template>

                    <template v-slot:[`item.installer`]="{ item }">
                      <equipment-installer
                        v-if="item.installer"
                        :installer-name="item.installer.name"
                      ></equipment-installer>
                      <i v-else :class="'far fa-times pl-2 text--disabled'"></i>
                    </template>

                    <template v-slot:[`item.accessoriesCount`]="{ item }">
                      <equipment-count :count="getAccessoriesCount(item)" />
                    </template>

                    <template v-slot:[`item.stats`]="{ item }">
                      <v-tooltip right z-index="999" nudge-right="-4px">
                        <template v-slot:activator="{ on, attrs }">
                          <div class="d-inline-block" v-bind="attrs" v-on="on">
                            <count-tag
                              @click.stop="displayStats(item)"
                              :count="item.countEquipmentUsed"
                              icon="fa-draw-square"
                            ></count-tag>
                          </div>
                        </template>
                        <span>
                          <span class="mr-1">{{ item.countEquipmentUsed }}</span>
                          {{
                            item.countEquipmentUsed == 1 ? "Connected Space" : "Connected Spaces"
                          }}
                          <br />
                          <hr class="my-1" style="opacity: 0.2" />
                          <span style="font-size: 12px">
                            <i class="fad fa-swap-opacity fa-hand-pointer mr-1"></i> Click for more
                            details!
                          </span>
                        </span>
                      </v-tooltip>
                    </template>

                    <template v-slot:[`item.model`]="{ item }">
                      <div class="equipment-model">{{ item.model }}</div>
                    </template>

                    <template v-slot:[`item.createdBy`]="{ item }">
                      <user-avatar :user="item.createdBy" icon></user-avatar>
                    </template>

                    <template v-slot:[`item.createDate`]="{ item }">
                      <dater :date="item.createDate" date-only></dater>
                    </template>

                    <template v-slot:[`item.updatedBy`]="{ item }">
                      <user-avatar :user="item.updatedBy" icon></user-avatar>
                    </template>

                    <template v-slot:[`item.updateDate`]="{ item }">
                      <dater :date="item.updateDate" date-only></dater>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                      <v-menu dense offset-x right>
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn icon elevation="0" v-bind="attrs" v-on="on">
                            <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
                          </v-btn>
                        </template>
                        <v-list class="more-options-menu">
                          <v-list-item
                            @click="update(item.id)"
                            v-if="$has(perms.Equipments.Update)"
                          >
                            <v-list-item-icon class="mr-2 justify-center">
                              <v-icon small>fal fa-pen</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Edit</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            @click="cloneEquipment(item.id)"
                            v-if="$has(perms.Equipments.Update)"
                          >
                            <v-list-item-icon class="mr-2 justify-center">
                              <v-icon small>fal fa-clone</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Clone</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click="displayStats(item)">
                            <v-list-item-icon class="mr-2 justify-center">
                              <v-icon small>fal fa-analytics</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Stats</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click="displayActivityLogs(item)">
                            <v-list-item-icon class="mr-2 justify-center">
                              <v-icon small>fal fa-history</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Activity Logs</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="my-2" v-if="$has(perms.Equipments.Delete)"></v-divider>
                          <v-list-item
                            @click="openDeleteReplaceDialog(item)"
                            v-if="$has(perms.Equipments.Delete)"
                          >
                            <v-list-item-icon class="mr-2 justify-center">
                              <v-icon small>fal fa-trash-undo purple--text</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title class="purple--text"
                                >Delete & Replace</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click="del(item.id)" v-if="$has(perms.Equipments.Delete)">
                            <v-list-item-icon class="mr-2 justify-center">
                              <v-icon small>fal fa-trash-alt red--text</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title class="red--text">Delete</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>

                    <template v-slot:no-data>
                      <img width="500" src="/img/art/fogg-no-connection-2.png" />
                      <p class="font-weight-bold">No Data Available!</p>
                    </template>
                    <template v-slot:loading>
                      <video width="250" muted loop autoplay style="padding: 0.5rem">
                        <source
                          src="/img/art/astronaut-loves-music-4980476-4153140.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <p class="font-weight-bold">Searching the Cosmos...</p>
                    </template>
                  </v-data-table>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
        <template v-slot:no-data>
          <div class="text-center">
            <img width="500" src="/img/art/fogg-no-connection-2.png" style="border-radius: 1rem" />
            <p class="font-weight-bold">No Data Available!</p>
          </div>
        </template>
        <template v-slot:loading>
          <v-row justify="center">
            <v-col sm="12" md="6" lg="4" class="d-flex flex-column text-center py-16">
              <p class="font-weight-bold">Loading Equipment Groups...</p>
              <v-progress-linear color="info" indeterminate rounded height="6"></v-progress-linear>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
      <template>
        <replace-equipment
          ref="replaceEquipmentComp"
          @Success="replaceEquipmentSuccedded()"
        ></replace-equipment>
      </template>
    </v-container>

    <edit-equipment
      @save="onEquipmentSaved"
      @close="onEquipmentClosed"
      force-allow-edit
      ref="editEquipment"
    ></edit-equipment>

    <equipment-activity-log ref="equipmentActivityLog"></equipment-activity-log>

    <equipment-stats ref="statsEquipment" :template="selected"> </equipment-stats>

    <!-- Exported template -->
    <v-dialog
      v-model="downloadEquipments.dialog"
      max-width="450px"
      style="z-index: 99999999"
      @click:outside="downloadEquipments.loading = true"
    >
      <v-fade-transition mode="out-in">
        <v-card v-if="downloadEquipments.loading" :key="'export-loading-card'">
          <v-card-text class="py-3">
            <h3 class="mb-2">Generating pdf...</h3>
            <v-progress-linear indeterminate color="info" class="mb-1" rounded></v-progress-linear>
          </v-card-text>
        </v-card>
        <v-card v-else :key="'export-result-card'">
          <v-card-title class="font-weight-bold" small>
            <i class="fad fa-file-arrow-down mr-2"></i>PDF file is ready!
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container class="py-0">
              <div class="text-center py-7 mt-3 d-flex justify-center align-center flex-column">
                <div class="report-file-icon" :class="downloadEquipments.reportFileExt">
                  <i class="fad fa-file-pdf" v-if="downloadEquipments.reportFileExt == 'pdf'"></i>
                </div>
                <h3 class="report-result mt-2">Report Generated</h3>
                <h3 class="report-name mt-2 text--secondary">
                  {{ downloadEquipments.reportFileName }}
                </h3>
                <v-btn
                  text
                  color="info"
                  class="report-download-link mt-2"
                  :href="downloadEquipments.reportUrl"
                  :download="downloadEquipments.reportFileName"
                >
                  <i class="fad fa-arrow-alt-to-bottom mr-2"></i>Download
                </v-btn>
              </div>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="
                downloadEquipments.dialog = false;
                downloadEquipments.loading = true;
              "
            >
              <i class="fal fa-chevron-left mr-2"></i> Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-fade-transition>
    </v-dialog>
  </div>
</template>

<script>
import perms from "../../../plugins/permissions";
import ApiService from "../services/equipments-service.js";
import ReplaceEquipment from "../components/ReplaceEquipment.vue";
import EquipmentAvailability from "../components/EquipmentAvailability.vue";
import EquipmentStats from "../components/EquipmentStats.vue";
import EquipmentActivityLog from "../components/activityLogs/EquipmentActivityLog.vue";
import EquipmentProvider from "../components/EquipmentProvider.vue";
import EquipmentInstaller from "../components/EquipmentInstaller.vue";
import equipmentHeader from "../config/tables/equipment.header";
import ExportGroups from "../components/ExportGroups.vue";
import FilterManager from "../../Shared/components/FilterManager/FilterManager.vue";
import {
  UserFilterSettings,
  ManufacturerFilterSettings,
  ProductFamilyFilterSettings,
  EquipmentCategoryFilterSettings,
  InstallerFilterSettings,
  ProviderFilterSettings,
} from "../../Shared/components/FilterManager/FilterSettings";

export default {
  components: {
    EquipmentStats,
    ReplaceEquipment,
    EquipmentProvider,
    EquipmentInstaller,
    EquipmentActivityLog,
    EquipmentAvailability,
    ExportGroups,
    FilterManager,
  },
  data() {
    return {
      perms: perms,
      printing: false,
      selectedFilters: [],
      storageKey: "Equipments",
      openEquipmentInEditMode: false,
      paramId: null,
      entities: [],
      selected: {},
      total: 0,
      inventoryTimerId: null,
      defaultHeaders: [],
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
        sortBy: "Key",
        sortDesc: false,
        createdByIds: [],
        updatedByIds: [],
        categoriesId: [],
        providerIds: [],
        installerIds: [],
        Make: [],
        GroupBy: "Tag",
        MinCount: 1,
      },
      currentGroupBy: "Tag",
      storedSearch: null,
      loadingStates: {
        table: false,
      },
      modals: {
        replaceEquipment: {
          active: false,
          sourceEquipment: {},
        },
      },
      headers: equipmentHeader,
      downloadEquipments: {
        dialog: false,
        loading: false,
        reportUrl: "",
        reportFileName: "",
        reportFileExt: "",
      },
    };
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.$route.query.q) this.options.search = this.$route.query.q;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    this.initFilterManagerSettings();
    // this.$moveable(document.querySelector(".equipments-table .v-data-table__wrapper"));
    // this.$refs.mainSearch.focus();
    // this.getDataDebounced();
  },
  methods: {
    initFilterManagerSettings() {
      var createdByFilterSettings = new UserFilterSettings();
      createdByFilterSettings.title = "Created By";
      createdByFilterSettings.model = "createdByIds";
      createdByFilterSettings.ref = "createdByFilter";

      var updatedByFilterSettings = new UserFilterSettings();
      updatedByFilterSettings.title = "Updated By";
      updatedByFilterSettings.model = "updatedByIds";
      updatedByFilterSettings.ref = "updatedByFilter";

      var manufacturerFilterSettings = new ManufacturerFilterSettings();

      var productFamilyFilterSettings = new ProductFamilyFilterSettings();

      var equipmentCategoryFilterSettings = new EquipmentCategoryFilterSettings();

      var installerFilterSettings = new InstallerFilterSettings();

      var providerFilterSettings = new ProviderFilterSettings();

      this.selectedFilters = [
        createdByFilterSettings,
        updatedByFilterSettings,
        manufacturerFilterSettings,
        productFamilyFilterSettings,
        equipmentCategoryFilterSettings,
        installerFilterSettings,
        providerFilterSettings,
      ];
    },
    download() {
      this.downloadEquipments.loading = true;
      setTimeout(() => {
        this.startExportingTemplate();
      }, 50);
    },
    startExportingTemplate() {
      this.downloadEquipments.dialog = true;
      let optionsToSend = this.cloneDeep(this.options);
      if (this.storedSearch != optionsToSend.search) optionsToSend.page = 0;
      this.storedSearch = optionsToSend.search;
      ApiService.download(this.$clean(optionsToSend, true))
        .then((resp) => {
          this.downloadEquipments.dialog = true;
          this.downloadEquipments.loading = false;
          let url = URL.createObjectURL(
            new Blob([resp.data], {
              type: resp.data.type,
            })
          );
          this.downloadEquipments.reportUrl = url;
          this.downloadEquipments.reportFileName = resp.headers["x-file-name"];
          this.downloadEquipments.reportFileExt = resp.headers["x-file-name"].split(".").pop();
        })
        .catch((err) => {
          this.downloadEquipments.dialog = false;
          this.downloadEquipments.loading = true;
          this.$handleError(err);
        });
    },
    updateRouterLink() {
      if (this.$route.query.q == this.options.search) return;
      if (this.options.search != null && this.options.search != "") {
        this.$router.push({
          path: "/equipment",
          query: { q: this.options.search },
          replace: true,
        });
      } else this.$router.push({ path: "/equipment", replace: true });
    },
    onEquipmentClosed() {},
    getAccessoriesCount(item) {
      if (item != null)
        return item.accessories.reduce((total, cur) => {
          return total + cur.count;
        }, 0);
      else return 0;
    },
    getData() {
      this.$backToTop();
      let optionsToSend = this.cloneDeep(this.options);
      if (this.storedSearch != optionsToSend.search) optionsToSend.page = 0;
      this.$log("######################## GET DATA ########################");
      this.$log("optionsToSend:", optionsToSend);
      this.storedSearch = optionsToSend.search;
      this.loadingStates.table = true;
      ApiService.duplicates(this.$clean(optionsToSend, true))
        .then((resp) => {
          this.$log("resp:", resp);
          this.$log("entities:", this.entities);
          this.currentGroupBy = this.getGroupByParam(
            resp.config.url.replace("equipments/Duplicates?", "")
          );
          this.total = resp.data.total;
          this.entities = resp.data.items;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    getGroupByParam(url) {
      const params = new Proxy(new URLSearchParams(url), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      return params.GroupBy;
    },
    del(id) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this equipment?`,
          title: `Delete Equipment?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return ApiService.delete(id)
                  .then((resp) => {
                    this.getDataDebounced();
                    this.$dialog.notify.success("Item deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("an error occurred during deleting the item", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    cloneEquipment(id) {
      this.$dialog
        .info({
          text: `Are you sure you want to clone this equipment?`,
          title: `Clone Equipment?`,
          color: "info",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "info",
              handle: () => {
                return ApiService.clone(id)
                  .then((resp) => {
                    const id = resp.data.id;
                    // this.options.search = id;
                    setTimeout(() => {
                      this.view(id);
                      this.getDataDebounced();
                    }, 1000);
                    this.$dialog.notify.success("Equipment cloned successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error(
                      "Oops! an error occurred during cloning this equipment!",
                      {
                        position: "top-right",
                        timeout: 3000,
                      }
                    );
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onEquipmentSaved(equipemntData, isNewEquipment) {
      // this.$log("onEquipmentSaved", equipemntData, isNewEquipment);
      // if (isNewEquipment) {
      //   this.addToArr(this.entities, equipemntData);
      //   // this.options.search = equipemntData.id;
      //   setTimeout(() => {
      //     this.view(equipemntData.id);
      //   }, 1000);
      // } else {
      //   this.updateArr(this.entities, equipemntData);
      // }
      this.getDataDebounced();
    },
    getDataDebounced() {
      if (this.inventoryTimerId == null) {
        this.inventoryTimerId = -1;
        this.updateRouterLink();
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.inventoryTimerId);

      // delay new call 400ms
      this.inventoryTimerId = setTimeout(() => {
        this.updateRouterLink();
        this.getData();
      }, 400);
    },
    openDeleteReplaceDialog(equipment) {
      this.modals.replaceEquipment.sourceEquipment = equipment;
      this.$refs.replaceEquipmentComp.open(equipment);
    },
    replaceEquipmentSuccedded() {
      this.getDataDebounced();
    },
    displayStats(item) {
      this.selected = JSON.parse(
        JSON.stringify({ ...this.entities.filter((e) => e.id == item.id)[0] })
      );
      setTimeout(() => {
        this.$refs.statsEquipment.open(item);
      });
    },
    displayActivityLogs(item) {
      setTimeout(() => {
        this.$refs.equipmentActivityLog.open(item);
      });
    },
    add() {
      this.selected = {};
      this.$refs.editEquipment.open(null);
    },
    update(id) {
      this.selected = { id: id };
      this.openEquipmentInEditMode = true;
      this.openEquipment(id);
    },
    view(id) {
      this.openEquipmentInEditMode = false;
      this.openEquipment(id);
    },
    openEquipment(id) {
      // this.$log("openEquipment =>>>", id, this.openEquipmentInEditMode);
      setTimeout(() => {
        this.$refs.editEquipment.open(id, this.openEquipmentInEditMode);
        this.openEquipmentInEditMode = false;
      }, 50);
    },
    rowClicked(row) {
      this.view(row.id);
    },
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        this.$log("options >>>>> ", "newVal", newVal, "oldVal", oldVal);
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, newVal.itemsPerPage);
        this.getDataDebounced();
      },
      deep: true,
    },
    "options.GroupBy": {
      handler(newVal, oldVal) {
        this.$log("options.GroupBy >>>>> ", "newVal", newVal, "oldVal", oldVal);
        if (newVal != oldVal) {
          this.options.page = 1;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.equipments-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}

.v-list-item__title {
  font-size: 14px !important;
}

.report-file-icon {
  width: 120px;
  height: 120px;
  border-radius: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 54px;
  overflow: hidden;
  transition: all 0.3s ease-out;

  &.xlsx {
    // background: rgba(#2b8c59, 0.06);
    // color: #2b8c59;
    background: rgba(#0d6e37, 0.06);
    color: #0d6e37;
  }
  &.docx {
    background: rgba(#134bb0, 0.06);
    color: #134bb0;
  }
  &.pdf {
    background: rgba(#e53935, 0.06);
    color: #e53935;
  }
}

.report-title-icon {
  width: 54px;
  height: 54px;
  border-radius: 54px !important;
  background: rgba($shades-black, 0.06);
  color: rgba($shades-black, 0.87);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  overflow: hidden;
  transition: all 0.3s ease-out;
}
</style>
